import React from 'react';
import { Link } from 'gatsby';
import Logo from '../components/Logo';
import '../../assets/styles/Web.scss';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <div className="Page404">
    <div className="Page404-main">
      <SEO title="404: Not Found" />
      <div className="Page404-logo">
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Logo />
        </Link>
      </div>
      <h1 className="Section-title">NOT FOUND</h1>
      <p className="Cover-mainTitle">Sorry, this page does not exist.</p>
      <Link to="/" style={{ textDecoration: 'none', marginTop: '80px' }}>
        <div className="Button Button--inverse">Return to homepage</div>
      </Link>
    </div>
  </div>
);

export default NotFoundPage;
