import React from 'react';

export default () => (
  <figure className="Cover-logo">
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      width="86px"
      height="29px"
      viewBox="0 0 77.5 26.25"
      enableBackground="new 0 0 77.5 26.25"
    >
      <g>
        <path
          fill="#f92644"
          d="M15.55,7.308c-1.044-1.332-2.952-2.195-4.535-2.195c-1.584,0-3.563,0.539-3.563,2.555
            c0,1.691,1.512,2.232,3.923,2.988c3.456,1.115,7.919,2.592,7.919,7.667c0,5.867-4.715,8.496-9.755,8.496
            c-3.635,0-7.307-1.332-9.539-3.672l4.031-4.104c1.224,1.548,3.492,2.699,5.507,2.699c1.872,0,3.528-0.72,3.528-2.771
            c0-1.943-1.944-2.557-5.292-3.636c-3.24-1.045-6.515-2.7-6.515-7.308c0-5.651,5.111-7.991,9.863-7.991
            c2.879,0,6.119,1.08,8.351,3.132L15.55,7.308z"
        />
        <path
          fill="#f92644"
          d="M35.638,26.169c-8.099,0-13.606-4.715-13.606-13.066C22.031,5.364,27.97,0,35.854,0
            c7.919,0,13.714,5.327,13.714,12.995c0,3.887-1.943,6.875-3.779,8.278v0.107l5.363-0.107v4.896H35.638z M35.854,5.579
            c-4.104,0-7.199,3.168-7.199,7.596c0,4.571,2.952,7.738,7.163,7.738c4.176,0,7.235-3.167,7.235-7.738
            C43.053,8.747,40.102,5.579,35.854,5.579z"
        />
        <path
          fill="#f92644"
          d="M70.412,26.169L60.154,9.503h-0.108l0.144,16.666h-5.975V0.685h7.02l10.223,16.629h0.107L71.421,0.685
            h5.976v25.484H70.412z"
        />
      </g>
    </svg>
  </figure>
);
